import React, { useRef } from 'react';
import { WindowLocation } from '@gatsbyjs/reach-router';
import FacilitySelect from '../facilities/facility-select';
import ExpandIcon from '../icons/expand';
import { isChildOfClubPage } from 'src/helpers/path-matching-helper';
import { shadow, zIndexes } from 'src/styling/constants';
import { TranslatedStaticText } from 'src/translators/static-text-translator';
import { WebsiteLink } from '../common/website-link';
import useOutsideClick from 'src/custom-hooks/use-outside-click';
import { useToggle } from 'src/custom-hooks/use-toggle';
import { HeaderSubmenuSelect } from './header-submenu-select';
import { SiteContext } from 'src/context/site-context';
import { StaticTextLookupKey } from 'src/translators/static-text-lookup';
import { ClubPageTypeProps, clubPageTypeHeaderContent } from './club-page-type';
import { translateLinkIntoLanguage } from 'src/translators/links-translator';
import { getBuildDomainForBuildType, newWebsiteBuildDomains } from 'src/enums/build/build-domain';

type ClubDropdownHeaderProps = {
  location: WindowLocation;
  clubPageType?: ClubPageTypeProps;
  displayText: string;
};

type NavTabProps = {
  isCollapsed: boolean;
  toggleCollapsed: () => void;
  displayText: string;
  shouldHighlight: boolean;
};

const NavTab: React.FC<NavTabProps> = ({
  isCollapsed,
  toggleCollapsed,
  displayText,
  shouldHighlight,
}) => {
  const { styling, buildType } = React.useContext(SiteContext);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));
  const oldActiveState = shouldHighlight ? styling.fonts.bold : styling.fonts.regular;
  const newActiveState = styling.fonts.regular;

  const wrapperStyles = isNewDesign
    ? {
        'borderBottom': '1px solid',
        'borderBottomColor': shouldHighlight ? styling.colors.capeCod : 'transparent',
        'lineHeight': '145%',
        '&:hover': {
          svg: { fill: styling.colors.camelot },
          color: styling.colors.camelot,
          borderBottomColor: styling.colors.camelot,
        },
      }
    : {};

  return (
    <div
      css={{
        display: 'flex',
        cursor: 'pointer',
        ...wrapperStyles,
      }}
      onClick={toggleCollapsed}
    >
      <div
        css={[styling.common.headerStyle, isNewDesign ? newActiveState : oldActiveState]}
        data-gtmid="nav-tab-club-facilities"
      >
        {displayText}
      </div>
      <div css={isNewDesign ? { display: 'flex', alignItems: 'center' } : {}}>
        <div
          css={{
            width: '1rem',
            height: '1rem',
            fill: isNewDesign ? styling.colors.capeCod : styling.colors.headerNavigationText,
          }}
          data-gtmid="nav-tab-facilities-dropdown"
        >
          <ExpandIcon
            fill={isNewDesign ? styling.colors.capeCod : styling.colors.headerNavigationText}
            isExpanded={!isCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

const ClubDropdownHeader: React.FC<ClubDropdownHeaderProps> = ({
  clubPageType,
  location,
  displayText,
}) => {
  const { siteId, styling, language, buildType, isBlaze } = React.useContext(SiteContext);
  const ref = useRef();
  const [collapsed, setCollapsed, collapse] = useToggle(true);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  useOutsideClick(ref, collapse);

  const onFinishMenuAction = () => collapse();

  const { childOfClubPageLink, seeAllTitleLookupKey, seeAllLink } =
    clubPageTypeHeaderContent(clubPageType);
  const shouldHighlight = isChildOfClubPage(location, siteId, childOfClubPageLink);
  const isNewDesign = newWebsiteBuildDomains.includes(getBuildDomainForBuildType(buildType));

  return (
    <div
      css={{
        padding: isNewDesign ? 'unset' : '1rem',
        margin: isNewDesign ? '0.875rem 1rem' : 'unset',
      }}
      ref={ref}
    >
      <NavTab
        isCollapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        displayText={displayText}
        shouldHighlight={shouldHighlight}
      />
      {collapsed ? null : (
        <div
          css={[
            shadow.headerBelow,
            {
              left: 0,
              right: 0,
              zIndex: zIndexes.clubFacilitiesHeader,
              position: 'absolute',
              backgroundColor: styling.colors.headerBackground,
              paddingBottom: '1rem',
              top: '100%',
              borderTop: isBlaze || isNewDesign ? `1px solid ${styling.colors.headerBorder}` : null,
            },
          ]}
        >
          <div css={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {clubPageType !== undefined ? (
              <HeaderSubmenuSelect type={clubPageType} onClick={onFinishMenuAction} />
            ) : (
              <FacilitySelect
                center={false}
                useSmallThumbnails
                gtmTrackingId="nav-tab-facility-link"
                onClick={onFinishMenuAction}
                isNav
              />
            )}
          </div>
          <WebsiteLink
            to={translateLinkIntoLanguage(seeAllLink, language)}
            handleClick={onFinishMenuAction}
          >
            <div
              css={[
                styling.common.link,
                { textAlign: 'center', display: 'inline-block', margin: '1rem 0' },
                isNewDesign ? { color: styling.colors.capeCod } : {},
              ]}
              data-gtmid="nav-tab-see-all-facilities"
            >
              {<TranslatedStaticText lookupKey={seeAllTitleLookupKey as StaticTextLookupKey} />}
            </div>
          </WebsiteLink>
        </div>
      )}
    </div>
  );
};

export default ClubDropdownHeader;
